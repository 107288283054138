import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <p>Another review app, but better and simpler ✌️</p>
    </div>
  );
}

export default App;
